.Residental {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    h1{
      font-size: 3rem;
    }
   
    .Residental__main {
      // height: 100%;
      flex: 1;
      .Residental__flex {
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 10px;
        justify-items: center;
        img {
          object-fit: cover;
          height: auto;
          margin-top: 10px;
          width: 100%;
  
          &:hover {
            filter: grayscale(1);
          }
          
        }
        .Residental__photos_wrapper{
          position: relative;
        }
        .Residental__title{
          position: absolute;
          left: 30px;
          top: 50px;
          color: black;
          font-size: 2rem;
          font-family: var(--ion-futur-bold);
        }
      }
    }
  
    @media (min-width: 50rem) {
      .Residental {
      }
      .Residental__main {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        .Residental__flex {
          gap: 10px;
          justify-content: center;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          .Residental__photos_wrapper{
            width: 40%;
            height: 100%;
            img {
              margin-top: 0px;
              width: 100%;
              height: auto;
              height: 400px;
            }
          }
        }
      }
    }
  }
  