
.home_component{
 
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
 
    // background-color: rgb(42,40,42)!important;

.home_component__container{
    flex: 1;
}

.page-section {
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    // background-color: rgb(42,40,42)!important;
}


.section-background {
    // position: relative;
    // display: block;
    // height: 600px;
    // width: 100%;
    // overflow:hidden;

}
.carousel {
    // min-width:900px;
    // height:600px;
    margin: auto;
    overflow: hidden;
    z-index: 0;
  }



.section-background__img{
    object-position: 30% 70%;
    width: 100vw;
    height: 100%;
    object-fit: cover;    
}


.carousel-item {
    height: 650px!important;//default value
    // height: calc(100vh - 124px);
    transition: transform 2s ease-in-out;
    transition: all 2s;
}

// .carousel-item-next{
//     transition: transform 1.5s ease-in-out;
//   }
  
//   .carousel-item-prev{
//       transition: transform 1.5s ease-in-out;
//   }
//   .carousel-item-left {
//       transform: translateX(-100%);
//   }
//   .carousel-item-right {
//       transform: translateX(100%);
//   }

.carousel-item.active {
    // height: 400px;
}

.carousel-control-prev{
  

}

@media screen and(min-width:500px) {
    .carousel-item {
        height: 600px;
        // transition: transform 2s ease-in-out;
        // transition: all 4s;
    }
    
}

@media screen and(min-width:670px) {
    .carousel-item {
        height: 600px;
        // transition: transform 2s ease-in-out;
        // transition: all 4s;
    }
    
}

// .carousel-control-prev .carousel-control-prev-icon{
//     background-repeat: no-repeat;
//     width: 4rem;
//     background-size: 100px 200px;
   
// }

// .carousel-control-next .carousel-control-next-icon{
//     background-repeat: no-repeat;
//     width: 4rem;
//     background-size: 100px 200px;
   
// }

// .carousel-control-prev-icon {
//     background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
//    }
   
//    .carousel-control-next-icon {
//      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
//    }


// .carousel-control-prev-icon,
// .carousel-control-next-icon {
//   height: 100px;
//   width: 100px;
//   outline: black;
//   background-size: 100%, 100%;
//   border-radius: 50%;
// //   border: 1px solid black;
//   background-image: none;
// }

// .carousel-control-next-icon:after
// {
//   content: '>';
//   font-size: 55px;
//   color: red;
// }

// .carousel-control-prev-icon:after {
//   content: '<';
//   font-size: 55px;
//   color: red;
// }
// footer{
//     min-height: 33vh;
//     background-color: white;
//     position: relative;
// }

}
