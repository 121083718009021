.About {
  display: flex;
  flex-direction: column;
  height: 100vh;
    main{
      // margin:0 30rem!important;    
      display: flex;;
      flex: 1;
      justify-content: center;
    }

  .wrapper {
    display: flex;;
    flex: 1;
  
    background-color: var(--bg-background);
    font-family: var(--ion-futur-light);
    
  
    section {
      background-color: white;
      height: 100%;
      width: 100vw;
  
    }

    .flex-wrapper {
      // margin: 0px 50px;
      // width: 1420px;
      width:  1420px;
      // max-width: 1420px;
      max-width: 1420px;
      // min-width: 1420px;
      min-width: 1420px;
      margin-top: 70px;
      display: flex;
      gap: 20px;
      flex: 1;
      .body_wrapper{
        display: flex;
        gap: 10px;
      }
      h2 {
        font-size: 2rem;
        margin-bottom: 30px;
        &:after {
          content: " ";
          display: block;
          border: 1px solid black;
        }
      }

      p {
        // color: balck;
        // font-size: 1.1rem;
        // letter-spacing: 0.5px;
        // // margin-top: 50px;
        // line-height: 30px;
        // max-height: 100%;

        color: balck;
        font-size: 21px;
        letter-spacing: 0.5px;
        line-height: 28px;
        max-height: 100%;
        
      }
      .left_img {
        // flex: 10% 1;
        // max-height: 300px;
        height: 220px;
        width: 220px;
        
        // align-self: flex-end;

      }
      .right_img {
        flex: 80%;
      }
    }
    
  }



  @media screen and (max-width: 2600px) {
    main{
      margin:0 20rem!important;    
    }
  }

  @media screen and (max-width: 2300px) {
    .left_img {
      // max-height: 200px!important;

  }
}

    @media screen and (max-width: 2200px) {
    main{
      margin:0 15rem!important;    
    }

    .left_img{
      // flex: 20% 1!important;
      // height: 200px!important;
    }

  }
  @media screen and (max-width: 1600px) {
    main{
      margin:0 1rem!important;    
    }

    .left_img{
      // height: 100px!important;
    }
    
  }
  @media screen and (max-width:1442px) {

    .flex-wrapper {
      min-width: unset!important;
    }

    .body_wrapper {
      // color: balck;
      // font-size: 1.1rem;
      // letter-spacing: 0.5px;
      // // margin-top: 50px;
      // line-height: 30px;
      // max-height: 100%;

     p{ color: balck;
      font-size: 18px!important;
      letter-spacing: 0.5px;
      line-height: 25px;
      max-height: 100%;
 
     }
  }
}

@media screen and (max-width:1415px) {

}

  @media (max-width: 1307px) {
    main{
      margin:0 3rem!important;
      
    }
    .left_img {
      // flex: 50%;
      // height: 200px!important;
      
    }
    .right_img{
      flex: 50% !important;
    }
    .body_wrapper {
      // color: balck;
      // font-size: 1.1rem;
      // letter-spacing: 0.5px;
      // // margin-top: 50px;
      // line-height: 30px;
      // max-height: 100%;

     p{ color: balck;
      font-size: 18px!important;
      letter-spacing: 0.5px;
      line-height: 25px!important;
      max-height: 100%;
 
     }
  }
  }


  @media (max-width: 1237px) {
  
    .body_wrapper {
      // color: balck;
      // font-size: 1.1rem;
      // letter-spacing: 0.5px;
      // // margin-top: 50px;
      // line-height: 30px;
      // max-height: 100%;

     p{ color: balck;
      font-size: 18px!important;
      letter-spacing: 0.5px;
      line-height: 22px!important;
      max-height: 100%;
 
     }
  }
  }
  @media (max-width: 992px) {
    main{
    margin:0 2rem!important;
    
  }
  .body_wrapper{
    flex-direction: column;
  }
    h2{
      font-size: 2rem;
    }
    p{
      font-size: 1rem!important;
      line-height: 25px!important;
      margin-top: 20px!important;
    }
    .left_img {
      flex: 20% !important;
      // height: 200px!important;
      align-self: flex-start!important;
      
    }
    .right_img{
      flex: 60% !important;
    }
  }

 
  @media (max-width: 768px) {
    main{

      padding:0 1rem;
    }
    .wrapper .flex-wrapper{ 
        flex-direction: column;
        margin: 10px;
        gap: 10px;
        height: 100%;
        img{
        height: 200px;
        width: 200px;
     }

     p{
        // color: red;
        font-size: 1.5rem;
        line-height: 40px;
     }
    }
  }

  @media (max-width: 499px) {
    main{

      padding:0 1rem;
    }
   .wrapper .flex-wrapper {
 
     height: 100%;
     

     img{
        height: 150px;
        width: 70%;
     }
     p{
        font-size: 1rem;
        line-height: 30px;
     }
    }
  }

 
 

 
  // @media screen and (min-width: 1400px) {
  //   main{
  //     margin:0 10rem!important;    
  //   }
  // }

  // @media screen and (min-width: 1600px) {
  //   main{
  //     margin:0 10rem!important;    
  //   }

  // }



  // @media screen and (min-width: 1900px) {
  //   main{
  //     margin:0 30rem!important;    
  //   }

  //   .left_img {
  //     height: 300px!important;
  //   }
  // }
}
