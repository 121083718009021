.Commercial {
  background-color: white;
  height: 100%;
 
  .Commercial__main {
    height: 100%;
    .Commercial__flex {
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      padding: 10px;
      justify-items: center;
      img {
        object-fit: cover;
        height: auto;
        margin-top: 10px;
        width: 100%;

        &:hover {
          filter: grayscale(1);
        }
        
      }
      .Commercial__photos_wrapper{
        position: relative;
        -webkit-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
        filter: grayscale(100%);
        transition: all 0.5s ease;
        &:hover {

          -webkit-filter: grayscale(0%);
          -moz-filter: grayscale(0%);
          filter: grayscale(0%);
        }
      }
      .Commercial__title{
        position: absolute;
        left: 30px;
        top: 50px;
        color: black;
        font-size: 2rem;
        font-family: var(--ion-futur-bold);
      }
    }
  }

  @media (min-width: 50rem) {
    .Commercial {
    }
    .Commercial__main {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      .Commercial__flex {
        gap: 10px;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        .Commercial__photos_wrapper{
          width: 40%;
          height: 100%;
          img {
            margin-top: 0px;
            width: 100%;
            height: auto;
            height: 400px;
          }
        }
      }
    }
  }
}
