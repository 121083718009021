*, *::before, *::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
    margin:0;
    padding:0;
    border:0;
    outline:0;
    font-size:100%;
    vertical-align:baseline;
    background:transparent;
}

body {
    line-height:1;
    /* background-color: red; */
    overflow-x: hidden;
    /* display:flex; 
    flex-direction:column;  */
    margin: 0;
    min-height: 100vh;
    
    
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

 @font-face {
  font-family: futur;
  src: url("./fonts/futura/futura light bt.ttf");
}


@font-face {
  font-family: futur-bold;
  src: url("./fonts/futura/Futura Bold font.ttf");
}

/* @font-face {
  font-family: Futura-Medium-Italic-font;
  src: url("./fonts/futura/Futura Medium Italic font.ttf");
}  */

:root {
  --ion-futur-bold:futur-bold;
  --ion-futur-light:futur;
  --Futura-Medium-Italic-font:Futura-Medium-Italic-font;
  --contact-background:#E0E0DA;
  /* --bg-background:#6f7070; */
  --bg-background:rgb(42, 40, 42);
  --bg-background:#545454;

}