/* .App{
    background-color: rgb(42,40,42)!important;
    height: 100vh;
    width: 100%;
} */


body{
    overflow-x: hidden;
    display:flex; 
    flex-direction:column; 
}

.App{

    background-color: var(--bg-background);
    height: 100%;
  
}

a{
    color: white;
    &:hover {
        color: white;
    }
}
