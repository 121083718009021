footer {
  width: 100%;
}

.footer_container {
  background-color: var(--bg-background);
  padding: 3em 0;
  display: flex;
  align-items: center;
  justify-content: center;

  align-items: center;
  width: 100%;
  position: relative;

  left: 0;
  bottom: 0;
  right: 0;
}


.footer_container .fa {
  // padding: 20px;
  font-size: 30px;
  width: 50px;
  text-align: center;
  text-decoration: none;
  // position: relative;
  float: right;
  // margin: 10px;
  // bottom: 20px;
}

.fa-instagram {
  // background: #125688;
  color: white;
}

.fa:hover {
  opacity: 0.7;
  filter: brightness(200%);
  color: gray;
}

.fa-facebook {
  // background: #3B5998;
  color: white;
}

.social_wrapper {
  position: relative;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 500px) {
 
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

.footer_container {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  
}
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
}
