.header_container {
  background-color: var(--bg-background);
  padding: calc(1.9rem);
  font-family: var(--ion-futur-light);
}

.button_contant {
  display: none;
}
.button_contant__inner {
  display: none;
}

.no_decoration {
  text-decoration: none;
}

.logo {
  display: inline-block;
  height: 5.9em;
  // height: 4.4em;
}

.logo-wrap {
  display: flex;
}

.logo-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
  padding-bottom: 0px;
  padding-top: 0px;
  width: 100%;
  // filter: brightness(0) invert(1) !important;
  align-items: center;
  justify-items: center;
}
h6 {
  text-align: center;
  margin: 0;
  padding: 0;
  letter-spacing: 5px;
  // position: relative;
  // right: 10px;
  // color: black;
  font-size: 12px;
}

.header-text-wrapper {
  font-size: 0.5em;
  // color: black;
  display: flex;
  flex-wrap: wrap;
  overflow: visible;
}

.header-text-wrapper span {
  border-left: 2px solid white;
  line-height: 1.6;
}

.header-text-inner {
  width: 80%;
}

.header {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

.intro-wrapper {
  height: calc(50vh - 180px);
}

.nav-bar__links {
  height: 100%;
  cursor: pointer;
  color: white;
  text-decoration: none;
}

.nav-bar__links:hover {
  color: white;
}

.intro-wrapper,
.intro {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.intro {
  padding: 1em;
}

.intro h1 {
  text-align: center;
  color: rgb(25, 25, 25);
  font-size: 18px;
  line-height: 23px;
  max-width: 100%;
  // font-family: ftnk;
  text-align: center;
  text-transform: none;
  font-weight: 400;
}

.hide_menuToggle {
  // display: none;
  position: absolute;
  left: 0;
  display: block;
  height: 100%;
  width: 20%;
  visibility: hidden;
  // transform: scale(1, 0);
  transition: 0.5s;
  z-index: 1;
  top: 0;
}

.menuToggle {
  position: fixed;
  display: block;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: white;
  transition: 0.5s;
  // transform: scale(1, 1);
  overflow: hidden;
  visibility: visible;
  overflow-y: auto;
  z-index: 1;
}

.hamburger {
  position: absolute;
  color: white;
  padding: 0px;
  text-decoration: none;
  font-size: 27px;
  display: block;
  // background-color: red!important;
  right: 0;
  padding: 20px 40px;
  z-index: 3;
  cursor: pointer;
}

.hamburger_active {
  // position: fixed;
  // color: black;
  // padding: 0px;
  // text-decoration: none;
  // font-size: 27px;
  display: none;
  // // background-color: red!important;
  // right: 0;
  // padding: 20px 40px;
  // z-index: 999;
}

.hamburger:active {
  // background-color: red!important;
  color: white;
  z-index: 999;
}
/* main body project covers */

.menu-container {
  display: flex;
  // position: relative;
  align-items: center;
  height: 100%;
  justify-content: center;
  color: #191919;
}

.close-button::before,
.close-button::after {
  background-color: var(--bg-background);
  content: "";
  display: block;
  height: 35px;

  position: absolute;
  width: 5px;
  // top: 30px;
  // right: 30px;
  visibility: visible;
}
.close-button {
  visibility: hidden;
  height: 40px;
  width: 40px;
}

.hide_close-button {
  visibility: hidden;
}

.close-button::before {
  transform: rotate(45deg);
}

.close-button::after {
  transform: rotate(-45deg);
}

@media screen and(max-width:670px) {
  .site-content {
    margin: 0;
    padding: 0 20px;
    width: 100%;
  }
} //End of media

.project-covers {
  display: flex;
  flex-wrap: wrap;
}

.project-cover {
  display: block;
  margin-bottom: 30px;
  margin-top: 0px;
}

.project-cover .cover-image,
.project-cover .cover-image:after,
.project-cover .cover {
  border-radius: 10px;
}

.project-cover .cover {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  min-height: 100%;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.project-cover .cover-image-wrap,
.project-cover .details-wrap {
  height: auto;
  position: relative;
  width: 100%;
  // min-width: 625px;
  float: none !important;
}

.project-cover .details {
  background: rgb(242, 242, 242);
  height: 100%;
  align-items: center;
}

.project-cover .details-inner {
  padding: 6% 8%;
  width: 100%;
  position: static;
  transform: none;
}

.project-cover .title {
  font-size: 14px;
  line-height: 17px;
  padding-bottom: 4px;
  color: rgb(25, 25, 25);
  float: none;
  // font-family: ftnk;

  font-style: normal;
  font-weight: 500;

  margin: auto;
  padding: 0px 0% 3px;
  text-align: center;
  text-transform: none;
  width: 100%;
}

.project-cover .date {
  font-size: 14px;
  line-height: 20px;
  margin: auto;
  padding: 0px 0%;
  text-align: center;
  text-transform: none;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  color: rgb(153, 153, 153);
  float: none;
  // font-family: ftnk;
}

/* side menu */

.show-menu {
  position: fixed;
  display: block;
  height: 100vh;
  width: 100vw;
  top: 0;
  background-color: white;

  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  transform: scale(1, 1);
  z-index: 999;
}

.hide-menu-content {
  // display: none;
  visibility: hidden;
  transform: scale(1, 0);
}
.menu-container {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.menu-items {
  width: 100%;
  text-align: center;
}

.menu-item {
  padding: 2em;
  letter-spacing: 5px;
  font-weight: bold;
  font-size: 1.3em;
  .selected {
    font-family: var(--ion-futur-bold);
  }
}

.menu-item a {
  color: black;
}

.menu-item:hover {
  filter: blur(1px);
  -webkit-filter: blur(1px);
  background-color: rgb(206, 206, 206);
}

.hide_menuitems {
  display: none;
}

.footer {
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
}

.footer {
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 41px;
  padding-top: 41px;
  text-align: center;
  text-transform: none;
  // font-family: ftnk;
  font-style: normal;
}
.footer-text {
  color: rgb(153, 153, 153);
}

.back-to-top {
  padding-bottom: 0px;
  padding-top: 41px;
  text-align: center;
  clear: both;
  width: 100%;
}
.back-to-top-text {
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  white-space: pre-wrap;
  // font-family: ftnk;
  font-style: normal;
  font-weight: 400;
  color: rgb(153, 153, 153);
}

.arrow {
  cursor: pointer;
  margin-right: 10px;
  color: rgb(153, 153, 153);
}

/*hide nav bar*/
nav {
  display: none;
}

@media screen and(min-width:670px) {
  /* allign container*/
  .top-wrapper .container {
    padding: 0 0px !important;
    margin: 0;
    width: 100%;
  }
  .top-wrapper {
    float: none;
    margin-left: auto;
    margin-right: auto;
    max-width: 1250px !important;
  }

  .project-cover {
    display: flex;
    // align-items: center;
  }

  .project-cover .details {
    display: flex;
    align-items: center;
  }

  .project-cover .title {
    font-size: 24px;
    line-height: 20px;
    padding-bottom: 5px;
    color: #191919;
    float: none;
    // font-family: ftnk;
    font-style: normal;
    font-weight: 540;
    margin: auto;
    padding-left: 0%;
    padding-right: 0%;
    padding-top: 0px;
    text-align: center;
    text-transform: none;
    width: 100%;
    // background-color: rgb(242, 242, 242)!important;
  }
  .project-cover .date {
    color: #999;
    float: none;
    // font-family: ftnk;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin: auto;
    padding-bottom: 0px;
    padding-left: 0%;
    padding-right: 0%;
    padding-top: 0px;
    text-align: center;
    text-transform: none;
    width: 100%;
    font-size: 20px;
    line-height: 20px;
  }

  /* center logo */
  .logo-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    max-width: 100%;
    padding-bottom: 0px;
    padding-top: 0px;
    width: 100%;
    // color: white;
    // filter: brightness(1);
  }
  h6 {
    position: relative;
    left: 5px;
    font-size: 26px;
  }

  /* nav bar */
  nav {
    display: block;
  }
  .nav-bar {
    list-style-type: none; /* Remove bullets */
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
    font-size: 1.3em;

    .selected {
      font-family: var(--ion-futur-bold);
    }
  }

  ._3myBa {
    // font-family: var(--ion-futur-bold);
    font-family: var(--ion-futur-light);
  }

  ._3myBb {
    display: inline-block;
    cursor: pointer;
    border-left: 1px solid white;
    padding-left: 0.5em;
    // font-family: var(--ion-futur-bold);
    font-family: var(--ion-futur-light);
  }

  .nav-bar ul {
    display: flex;
    cursor: pointer;
  }

  .nav-bar li {
    // margin-left:  1.5em;
    margin-left: 0.75em;
    margin-bottom: 0;
    font-weight: 500;
    // font-size: 0.8em;
    font-size: clamp(1rem, 0.8vw, 2rem);
    position: relative;
  }

  .nav-bar li::before {
    content: "";
    display: block;
    height: 2px;
    background: white;
    position: absolute;
    // width: 122%;
    top: -0.75em;
    left: 0;
    right: 0;
    // margin-left: 10px;
    transform: scale(0, 1);
    // display: none;
    transition: transform ease-in-out 250ms;
  }

  .nav-bar li:hover::before {
    transform: scale(1, 1);
    // display: block;
  }
  .project-cover:hover .details {
    cursor: pointer;
    //   background-color: black!important;
  }
  .project-cover:hover .logo {
    cursor: pointer;
    opacity: 0.5;
    z-index: 10;
  }

  .project-cover:hover .cover-image {
    background-color: rgb(87, 87, 87);
  }

  .project-cover:hover .title {
    color: white;
  }

  /* header from sticky to block */
  .header {
    position: relative;
    // background-color: white;
  }

  /* resize images */
  .project-cover .cover-image-wrap,
  .project-cover .details-wrap {
    min-width: 625px;
  }

  .logo {
    height: 7rem;
  }
} //End of Min Screen

@media screen and(min-width:900px) {
  .header_container {
    background-color: var(--bg-background);
    padding: 1rem;
    font-family: var(--ion-futur-light);
    color: white;
  }

  .button_contant {
    position: absolute;
    right: 50px;
    top: 70px;
    display: block;
  }
  .button_contant__inner {
    display: block;
    position: relative;
    z-index: 2;
    width: 100%;
    box-sizing: border-box;
    padding: 1rem 2.5rem;
    pointer-events: auto;
    border: 2px solid white;
    color: white;
    text-transform: uppercase;
    &:hover {
      color: rgb(42, 40, 42);
      background-color: white;
    }
  }

  .hamburger,
  .hamburger_active {
    display: none;
  }

  .menuToggle {
    display: none;
    transform: scale(0, 0);
  }
}
