.Gallery {
  background-color: white;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .isClose {
    // visibility: hidden;
    display: none;
    // background-color: red;
    transition: display ease 15s;
    animation: fadein 0.5s;
  }
  .isOpen {
    display: block;
    transition: display ease 15s;
  }

  .filter-color {
    // filter: invert(32%) sepia(34%) saturate(307%) hue-rotate(154deg)
    //   brightness(93%) contrast(90%);
  }
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .modal_wrapper {
    transition: opacity ease 10s;

    .hideArrow {
      display: none;
    }

    .showArrow {
      display: block;
    }

    .modal__left_arrow {
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      left: 20px;
      width: 100px;
      height: 80px;
      &:hover {
        cursor: pointer;

      }
    }

    .expand_button {
      position: absolute;
      width: 40px;
      height: 40px;
      top: 20px;
      left: 20px;
      &:hover {
        cursor: pointer;
      
        // color: #37474f;
      }
    }
    .modal__right_arrow {
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      right: 20px;
      width: 100px;
      height: 80px;
      transition: all ease 5s;
      &:hover {
        cursor: pointer;
        // color: #37474f;
      }
    }

    .modal {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgb(0, 0, 0);
      color: white;
      width: 100%;
      height: 100%;
      position: fixed;
      display: block;
      overflow: auto;
      opacity: 0.8;

      z-index: 4;
    }
    .modal_details {
      position: fixed;
      top: 0%;
      bottom: 0;
      left: 0;
      right: 0;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      display: flex;
      z-index: 998;
      .modal_image_wrapper {
        background: white;
        width: 80rem;
        height: 40rem;
        transition: all ease 1s;

        .modal_image {
          width: 100%;
          height: 100%;
          max-height: 100%;
          background-size: cover;
          position: relative;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
          background-position: 50% 50%;
        }
        h2 {
          opacity: 1;
        }
        .btClose {
          width: 3rem;
          height: 3rem;
          position: absolute;
          right: 20px;
          top: 20px;
          cursor: pointer;
          &:hover {
            box-shadow: 0 0 20px -5px rgba(white, 0.5);
          }
        }
        //Start of exit btn
        .close {
          position: absolute;
          right: 20px;
          top: 20px;
          $size: 60px;
          $borderSize: 2px;
          $borderColor: rgba(white, 1);
          $speed: 0.5s;
          width: $size;
          height: $size;
          // position: relative;
          // background: #455a64;
          background: black;
          border-radius: 50%;
          box-shadow: 0 0 20px -5px rgba(white, 0.5);
          transition: 0.25s ease-in-out;
          cursor: pointer;
          animation: fade-in $speed ease-out 0.25s both;
          @keyframes fade-in {
            from {
              opacity: 0;
              transform: scale(1.1);
            }
          }
          .circle {
            path {
              stroke: $borderColor;
              fill: none;
              stroke-width: $borderSize/2;
              stroke-linecap: round;
              animation: progress $speed ease-out 0.25s both;
              @keyframes progress {
                from {
                  stroke-dasharray: 0 100;
                }
              }
            }
          }
          span {
            display: block;
            width: ($size/4) - 2px;
            height: 4px;
            opacity: 1;
            background: $borderColor;

            box-shadow: 0 0 20px -5px rgba(white, 0.5);
            border-radius: 20px;
            position: absolute;
            $padding: $size/3;
            transition: 0.25s ease-in-out;
            animation: slide-in $speed ease-in-out 0.25s both;
            @keyframes slide-in {
              from {
                width: 0;
              }
            }
            &:nth-child(1) {
              top: ($padding - ($borderSize/2));
              left: $padding;
              transform: rotate(45deg);
              transform-origin: top left;
            }
            &:nth-child(2) {
              top: ($padding - ($borderSize/2));
              right: $padding;
              transform: rotate(-45deg);
              transform-origin: top right;
            }
            &:nth-child(3) {
              bottom: ($padding - ($borderSize/2));
              left: $padding;
              transform: rotate(-45deg);
              transform-origin: bottom left;
            }
            &:nth-child(4) {
              bottom: ($padding - ($borderSize/2));
              right: $padding;
              transform: rotate(45deg);
              transform-origin: bottom right;
            }
          }
          &:hover {
            // background: #37474f;
            span {
              width: ($size/4);
            }
          }
        }

        // End of exit btn
      }
    }
  }
  //End of Modal wrapper

  .column4-1 {
    grid-template-columns: repeat(4, 1fr);
  }
  .column3-1 {
    grid-template-columns: repeat(3, 1fr);
  }
  .container {
    margin: 0 auto;
    padding: 20px 0px;
    display: grid;
    // grid-template-columns: repeat(4,1fr);
    grid-auto-rows: 100px 300px;
    grid-gap: 10px;
    grid-auto-flow: dense;

    // cursor: zoom-in;
    &:hover {
      cursor: zoom-in;
    }
    .gallery-item {
      width: 100%;
      height: 100%;
      position: relative;
    }
    // .image {
    //   width: 100%;
    //   height: 100%;
    //   overflow: hidden;

    //   img {
    //     width: 100%;
    //     height: 100%;
    //     object-fit: cover;
    //     object-position: 30% 70%;
    //     transition: 0.5s ease-in-out;
    //     &:hover {
    //       transform: scale(1.5);
    //     }
    //   }
    // }
  }
 

  .Gallery__photos_header{
    max-width: 544px;
    margin: 0 auto;
    display: block;
    width: 100%;
  }
  .Gallery__photos_description{
    max-width: 1520px;
  }
  .Gallery__photos_wrapper{
    display: inline-block;
    
  }

  .Gallery__photos_description{
    text-align: center;
    font-family: var(--ion-futur-light);
    font-size: clamp(0.9rem, 1vw + 0.5rem, 2rem);
  }
  .Gallery__photos_title {
    // font-size: 2rem;
    // margin: 0 auto 10px auto;
    font-family: var(--ion-futur-light);
    font-size: clamp(0.9rem, 1vw + 0.5rem, 2rem);
    font-weight: bold;
    margin-bottom: 5px;
    text-align: left;
  }

  .Gallery__photos_location{
    text-align: left;
    font-family: var(--ion-futur-light);
    font-size: clamp(0.9rem, 1vw + 0.3rem, 2rem);
    margin-bottom: 30px;
  }
  .modal_wrapper {
    .circle {
      display: none;
    }
  }
  .w-1 {
    grid-column: span 1;
  }

  .w-2 {
    grid-column: span 2;
  }

  .w-3 {
    grid-column: span 3;
  }

  .w-4 {
    grid-column: span 4;
  }

  .w-5 {
    grid-column: span 5;
  }

  .w-6 {
    grid-column: span 6;
  }

  .h-1 {
    grid-row: span 1;
  }

  .h-2 {
    grid-row: span 2;
  }

  .h-3 {
    grid-row: span 3;
  }

  .h-4 {
    grid-row: span 4;
  }

  .h-5 {
    grid-row: span 5;
  }

  .h-6 {
    grid-row: span 6;
  }

    @media screen and (max-width:500px){
        .container{
          grid-template-columns: repeat(1,1fr);
        }
        .w-1,.w-2,.w-3,.w-4,.w-5,.w-6{
        grid-column:span 1;
        }
        
        .h-1,.h-2,.h-3,.h-4,.h-5,.h-6{
        grid-row: span 3;
        }
      }
      
      
      
   


  @media (min-width: 50rem) {
    .Gallery__photos_header{
      max-width: 960px;
      margin: 0 auto;
      display: block;
      width: 100%;

    }
    .Gallery__photos_description{
      max-width: 960px;
      margin: 0 auto;
    }
    .Gallery__photos_wrapper{
      display: inline-block;
    }
    .Gallery__photos_title {
      // font-size: 2rem;
      // margin: 0 auto 10px auto;
      margin-top: 20px;
      text-align: left;
      margin-bottom: 10px;
    }

    .Gallery__photos_location{
      margin-bottom: 50px;
      text-align: left;
    }
  }

  @media (min-width: 1200px){
    .Gallery__photos_header{
      max-width: 1140px;
    }

    .Gallery__photos_description{
      max-width: 1140px;
   
    }
    }

  
  @media (min-width: 1400px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
      max-width: 1520px;
    }

    .Gallery__photos_header{
      max-width: 1520px;
    }

    .Gallery__photos_description{
      max-width: 1520px;
   
    }
  }
  
  @media (min-width: 793px) and (max-width: 1220px) {
    .Gallery {
      height: 100vh;
    }
    .Gallery .Gellery__wrapper {
      // height: 100%;
      // width: 100%;
      // margin: 20px 0;
      img {
        // width: 43%;
        // height: 300px;
        object-fit: cover;
      }
    }
  }
}


