.Services {
    background-color: white;
    height: 100%;
  .wrapper{
    height: 100%;
  }
  .wrapper  div{
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    background-color: white;
  }
  
  h1 {
      text-align: center;
      margin: 0;
      padding: 0;
      letter-spacing: 5px;
      color: black;
      font-size: 20px;
      font-family: var(--ion-futur-bold);
    }
    .title{
        
    }
    .title__subtitle{
        font-size: 2rem;
        margin: 1rem;
    }
  
    .wrapper  span{
        text-align: center;
        width: 100%;
       
        
    }
    .wrapper  p{
        padding: rem 4rem;
        font-size: 1.7rem;
    }

  @media screen and (min-width: 670px) {
    h1 {
    //   position: relative;
      left: 5px;
      font-size: 26px;
    }
    
  }
}
