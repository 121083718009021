.Projects {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .Projects__flex {
    flex: 1;
    display: flex;
    background-color: white;
    gap: 0px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .no_decoration {
    text-decoration: none;
  }

  .Projects__img > img {
    // object-position: 50% 70%;
    width: 100vw;
    height: 300px;
    object-fit: cover;
    margin: 2rem 0;
    z-index: -2;
    
    
  }

  .Projects__img {
    position: relative;
    -webkit-filter: grayscale(100%);
   -moz-filter: grayscale(100%);
   filter: grayscale(100%);
   transition: all 0.5s ease;
    .title {
      position: absolute;
      color: white;
      top: 200px;
      font-size: 2rem;
      left: 40px;

      font-family: var(--ion-futur-bold);
    }
    &:hover {

      -webkit-filter: grayscale(0%);
      -moz-filter: grayscale(0%);
      filter: grayscale(0%);
    }
  }
  .after_left {
    position: relative;
    &::after {
      content: "3D VISUALIZATION";
      color: black; 
      bottom: 5px;
      display: block;
      width: 100%;
      text-align: center;
      font-size: 25px;
      font-weight: bold;
      margin-bottom: 20px;
      font-family: var(--ion-futur-light);
    }
  }

  .after_right {
    position: relative;
    &::after {
    content: "RESIDENTIAL";
    color: black; 
    bottom: 5px;
    display: block;
    width: 100%;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 20px;
    font-family: var(--ion-futur-light);
    }
  }

  @media (min-width: 50rem) {
    .Projects__flex {
      display: flex;
      background-color: white;
      gap: 20px;
      justify-content: center;
      flex-direction: row;
      align-items: center;
    }

    .Projects__img > img {
      // object-position: 50% 70%;
      width: 700px;
      height: auto;
      height: 500px;
      object-fit: cover;
    }

    .Projects__img {
      position: relative;
      // height: 500px;
      .title {
        top: 300px;
        left: 70px;
      }
    }
  }
}
