.image {
    width: 100%;
    height: 100%;
    overflow: hidden;

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 30% 70%;
      transition: 0.5s ease-in-out!important;
      &:hover {
        transform: scale(1.5);
      }
    }
  }